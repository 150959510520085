import { useFetcher } from '@remix-run/react'

import { Button, TextInput, Loader } from '~/design-system'
import { useOnFetcherLoaded } from '~/lib/fetcherStatus'

import { LoginFormData } from '../../hooks/useUpdateStep'

interface EnterNewPasswordStepProps {
  errorMessage: string
  updateStep: (stepData: LoginFormData) => void
  email?: string
  logo: React.ComponentType
}

const PASSWORD_MIN_LENGTH = 8

export default function EnterNewPasswordStep(props: EnterNewPasswordStepProps) {
  const { errorMessage, updateStep, email, logo: Logo } = props
  const fetcher = useFetcher<LoginFormData>()
  const enterVerificationCodeFetcher = useFetcher<LoginFormData>({
    key: 'enter-verification-code',
  })
  const isSubmitting = fetcher.state !== 'idle'

  useOnFetcherLoaded(() => {
    updateStep(fetcher.data)
  }, fetcher)

  return (
    <fetcher.Form method="post">
      <div className="flex flex-col items-center mt-6">
        <div className="w-full max-w-sm flex flex-col items-center relative">
          {isSubmitting && <Loader />}
          <Logo />
          <h1 className="text-xl mt-4">Welcome to Circle Medical!</h1>
          <p className="text-neutral-50-secondary-text text-sm mt-2 text-center">
            Create your password to access your appointments
          </p>
          <div className="w-full mt-8">
            <TextInput
              label="Password"
              size="small"
              required
              name="password"
              type="password"
              autoFocus={!isSubmitting}
              minLength={PASSWORD_MIN_LENGTH}
            />
          </div>
          <div className="w-full mt-4">
            <TextInput
              label="Password Confirmation"
              size="small"
              required
              name="password-confirmation"
              type="password"
              minLength={PASSWORD_MIN_LENGTH}
            />
          </div>
          {errorMessage && (
            <p className="text-danger-30-primary text-sm mt-4">
              {errorMessage}
            </p>
          )}
          <div className="w-full mt-4">
            <Button
              fullWidth
              name="step"
              submit
              value={'enter-new-password'}
              disabled={isSubmitting}
            >
              Create Password
            </Button>
          </div>
          <input hidden name="email" defaultValue={email} />
          <input
            hidden
            name="authentication-token"
            defaultValue={
              enterVerificationCodeFetcher?.data?.authenticationToken
            }
          />
        </div>
      </div>
    </fetcher.Form>
  )
}
