import { FetcherWithComponents, useFetcher } from '@remix-run/react'
import clsx from 'clsx'

import useCountdown from '../../hooks/useCountdown'
import { LoginFormData } from '../../hooks/useUpdateStep'
import { useOnFetcherLoaded } from '~/lib/fetcherStatus'

interface ResendOrVerifyCodeProps {
  email?: string
  isVerifyAccountSubmitting: boolean
  updateStep: (stepData: LoginFormData) => void
}

function ResendOrVerifyCode(props: ResendOrVerifyCodeProps) {
  const resendCodeFetcher = useFetcher<{ email: string }>()
  const verifyByEmailFetcher = useFetcher<{ email: string }>()
  const { email, isVerifyAccountSubmitting, updateStep } = props
  const { hasTimeLeft, reset: resetTimer, timeLeft } = useCountdown(60)
  const isResendCodeSubmitting = resendCodeFetcher.state === 'submitting'
  const isVerifyByEmailSubmitting = verifyByEmailFetcher.state === 'submitting'
  const isSubmitting =
    isVerifyAccountSubmitting ||
    isResendCodeSubmitting ||
    isVerifyByEmailSubmitting
  const resendCodeButtonIsDisabled = isSubmitting || hasTimeLeft

  useOnFetcherLoaded(() => {
    updateStep(verifyByEmailFetcher.data)
  }, verifyByEmailFetcher)

  useOnFetcherLoaded(() => {
    updateStep(resendCodeFetcher.data)
  }, resendCodeFetcher)

  function resendCode(
    medium: 'sms' | 'email',
    fetcher: FetcherWithComponents<{ email: string }>,
  ) {
    resetTimer()
    const formData = new FormData()

    formData.append('step', 'enter-verification-code')
    formData.append('action', `send-code-by-${medium}`)
    formData.append('email', email!)

    fetcher.submit(formData, {
      method: 'POST',
      action: '/login',
    })
  }

  return (
    <p className="text-neutral-40-placeholder text-sm mt-4">
      <button
        className={clsx({
          'text-neutral-40-placeholder': resendCodeButtonIsDisabled,
          'text-primary-30-default': !resendCodeButtonIsDisabled,
        })}
        disabled={resendCodeButtonIsDisabled}
        onClick={() => resendCode('sms', resendCodeFetcher)}
        type="button"
      >
        Resend Code {hasTimeLeft && `in ${timeLeft}`}
      </button>{' '}
      {!hasTimeLeft && (
        <>
          <span>or</span>{' '}
          <button
            className="text-primary-30-default"
            disabled={isVerifyAccountSubmitting}
            onClick={() => resendCode('email', verifyByEmailFetcher)}
            type="button"
          >
            Verify by Email
          </button>
        </>
      )}
    </p>
  )
}

export default ResendOrVerifyCode
