import { useSearchParams } from '@remix-run/react'

import logo from './assets/logo.svg'
import zocdocLogo from './assets/zocdoc-logo.svg'

function ZocdocLogo() {
  return (
    <div className="w-full p-6 bg-primary-0 flex justify-center rounded-lg">
      <img src={zocdocLogo} alt="Zocdoc logo" />
    </div>
  )
}

function CircleMedicalLogo() {
  return <img src={logo} alt="" className="max-w-16" />
}

function Logo() {
  const [searchParams] = useSearchParams()

  if (searchParams.get('zocdoc') === 'true') return <ZocdocLogo />

  return <CircleMedicalLogo />
}

export default Logo
