import { useState, useEffect, useCallback } from 'react'

function formatTime(timeInSeconds: number): string {
  const minutes = Math.floor(timeInSeconds / 60)
  const seconds = timeInSeconds % 60
  return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
}

function useCountdown(initialTimeInSeconds: number) {
  const [timeLeft, setTimeLeft] = useState(initialTimeInSeconds)
  const hasTimeLeft = timeLeft > 0

  useEffect(() => {
    if (timeLeft === 0) return

    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1)
    }, 1000)

    return () => clearInterval(intervalId)
  }, [timeLeft])

  const reset = useCallback(() => {
    setTimeLeft(initialTimeInSeconds)
  }, [initialTimeInSeconds])

  return { timeLeft: formatTime(timeLeft), hasTimeLeft, reset }
}

export default useCountdown
