import { useFetcher } from '@remix-run/react'

import { Button, TextInput, Loader } from '~/design-system'
import { useOnFetcherLoaded } from '~/lib/fetcherStatus'
import { maskVerificationCode } from '~/design-system/TextInput'

import { LoginFormData } from '../../hooks/useUpdateStep'
import ResendOrVerifyCode from './ResendOrVerifyCode'

interface EnterVerificationCodeStepProps {
  errorMessage: string
  updateStep: (stepData: LoginFormData) => void
  email?: string
  logo: React.ComponentType
}

export default function EnterVerificationCodeStep(
  props: EnterVerificationCodeStepProps,
) {
  const { errorMessage, updateStep, email, logo: Logo } = props
  const fetcher = useFetcher<LoginFormData>({ key: 'enter-verification-code' })
  const emailStepFetcher = useFetcher<LoginFormData>({
    key: 'enter-email',
  })
  const isSubmitting = fetcher.state !== 'idle'

  useOnFetcherLoaded(() => {
    updateStep(fetcher.data)
  }, fetcher)

  return (
    <fetcher.Form method="post">
      <input hidden name="email" defaultValue={email} />
      <div className="flex flex-col items-center mt-6">
        <div className="w-full max-w-sm flex flex-col items-center relative">
          {isSubmitting && <Loader />}
          <Logo />
          <h1 className="text-xl mt-4">{emailStepFetcher?.data?.title}</h1>
          <p className="text-neutral-50-secondary-text text-sm mt-2 text-center">
            {emailStepFetcher?.data?.message}
          </p>
          <div className="w-full mt-8">
            <TextInput
              label="Verification Code"
              size="medium"
              required
              name="verification-code"
              type="text"
              inputMode="numeric"
              mask={maskVerificationCode}
              textAlign="center"
              autoFocus={!isSubmitting}
            />
          </div>
          {errorMessage && (
            <p className="text-danger-30-primary text-sm mt-4">
              {errorMessage}
            </p>
          )}
          <div className="w-full mt-4">
            <Button
              fullWidth
              name="step"
              submit
              value={'enter-verification-code'}
              disabled={isSubmitting}
            >
              Verify Account
            </Button>
          </div>
          <p className="text-neutral-40-placeholder text-sm mt-4">
            Didn't receive a code?
          </p>
          <ResendOrVerifyCode
            email={email}
            isVerifyAccountSubmitting={isSubmitting}
            updateStep={updateStep}
          />
        </div>
      </div>
    </fetcher.Form>
  )
}
