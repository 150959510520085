import {
  type MetaFunction,
  type ActionFunction,
  type ActionFunctionArgs,
  type LoaderFunction,
  data,
} from '@remix-run/node'
import { LoginStep, LoginForm } from '~/domains/accounts'
import {
  redirectIfAuthenticated,
  enterEmail,
  enterPassword,
  resetPassword,
  enterNewPassword,
  enterVerificationCode,
} from '~/domains/accounts/server'
import { chainMiddlewares } from '~/lib/application'
import {
  AnalyticsContext,
  withAnalytics,
  withHandledErrors,
} from '~/common/middlewares'
import { createClient } from '~/lib/api'
import { createPageName } from '~/common'

export const handle = {
  pageName: 'Login',
}

export const meta: MetaFunction = () => {
  return [{ title: createPageName('Login') }]
}

export const loader: LoaderFunction = async ({ request }) => {
  await redirectIfAuthenticated(request)

  return data({})
}

export const action: ActionFunction = async ({
  request,
}: ActionFunctionArgs) => {
  return chainMiddlewares(
    withHandledErrors,
    withAnalytics(request),
    async (context) => {
      const { Analytics } = context as AnalyticsContext
      const body = await request.formData()
      const step = body.get('step') as LoginStep
      const api = createClient({ request })

      if (step === 'enter-email') {
        return enterEmail(body, api)
      } else if (step === 'enter-password') {
        return enterPassword(body, request, Analytics, api)
      } else if (step === 'enter-verification-code') {
        return enterVerificationCode(body, api)
      } else if (step === 'enter-new-password') {
        const email = body.get('email') as string
        const apiWithAuth = createClient({
          authToken: body.get('authentication-token') as string,
          userEmail: email,
          request,
        })
        return enterNewPassword(email, body, request, Analytics, apiWithAuth)
      } else if (step === 'password-reset') {
        return resetPassword(body, Analytics, api)
      } else {
        return data({
          errorMessage: 'Something went wrong',
        })
      }
    },
  )
}

export default function Login() {
  return <LoginForm />
}
