import { useState } from 'react'

import {
  LoginAction,
  LoginStep,
  nextStep,
  previousStep,
  Status,
} from '../business/LoginForm/loginSteps'

export type LoginFormData =
  | {
      status?: Status
      email?: string
      errorMessage?: string
      title?: string
      message?: string
      authenticationToken?: string
    }
  | undefined

function useUpdateStep(initialStep: LoginStep) {
  const [step, setStep] = useState<LoginStep>(initialStep)
  const [errorMessage, setErrorMessage] = useState('')

  function clearErrorMessage() {
    setErrorMessage('')
  }

  function performAction({
    action,
    step,
  }: {
    action: LoginAction
    step: LoginStep
  }) {
    clearErrorMessage()
    if (action === 'go-back') {
      setStep(previousStep(step))
    } else if (action === 'go-to-step') {
      setStep(step)
    }
  }

  function updateStep(stepData: LoginFormData) {
    if (stepData?.errorMessage) {
      setErrorMessage(stepData?.errorMessage)
      return
    } else clearErrorMessage()

    if (stepData?.status) {
      setStep(nextStep(step, { status: stepData?.status }))
      return
    }
  }

  return { step, errorMessage, updateStep, performAction, setErrorMessage }
}

export default useUpdateStep
