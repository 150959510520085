export type Status =
  | 'active'
  | 'nonexistent'
  | 'unverified'
  | 'valid-code'
  | 'valid-new-password'
  | 'forgot-password'

export type LoginStep =
  | 'enter-email'
  | 'enter-password'
  | 'enter-verification-code'
  | 'enter-new-password'
  | 'password-reset'

export type LoginAction = 'go-back' | 'go-to-step'

export const nextStep = (
  step: LoginStep,
  data: { status: Status },
): LoginStep => {
  switch (step) {
    case 'enter-email': {
      if (data.status === 'active') return 'enter-password'
      else if (data.status === 'unverified') return 'enter-verification-code'
      return step
    }

    case 'enter-verification-code': {
      if (data.status === 'valid-code') return 'enter-new-password'
      return step
    }

    case 'enter-password': {
      if (data.status === 'forgot-password') return 'password-reset'
      return step
    }

    default:
      return 'enter-email'
  }
}

export const previousStep = (step: LoginStep): LoginStep => {
  switch (step) {
    case 'enter-password':
      return 'enter-email'

    case 'password-reset':
      return 'enter-password'

    default:
      return 'enter-email'
  }
}
