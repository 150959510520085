import { useEffect, useState } from 'react'

import EnterEmailStep from './EnterEmailStep'
import EnterPasswordStep from './EnterPasswordStep'
import EnterVerificationCodeStep from './EnterVerificationCodeStep'
import ForgotPasswordStep from './ForgotPasswordStep'
import EnterNewPasswordStep from './EnterNewPasswordStep'
import Logo from './Logo'

import { LoginAction, LoginStep } from '../../business/LoginForm/loginSteps'
import useUpdateStep, { LoginFormData } from '../../hooks/useUpdateStep'
import { useLocation } from '@remix-run/react'

const isForceLogoutErrorMessage =
  "You've been logged out. Please note that you can only log in to your Circle Medical account from one device at a time for security reasons."

interface PerformActionProps {
  action: LoginAction
  step: LoginStep
}

interface LoginStepProps {
  goToPreviousStep: () => void
  errorMessage: string
  updateStep: (stepData: LoginFormData) => void
  email: string | undefined
  setEmail: (email: string | undefined) => void
  performAction: ({ action, step }: PerformActionProps) => void
  logo: React.ComponentType
}

const loginSteps: Record<
  LoginStep,
  (props: LoginStepProps) => React.JSX.Element
> = {
  'enter-email': (props: LoginStepProps) => {
    const {
      goToPreviousStep: _goToPreviousStep,
      performAction: _performAction,
      ...emailStepProps
    } = props
    return <EnterEmailStep {...emailStepProps} />
  },
  'enter-password': (props: LoginStepProps) => {
    const {
      setEmail: _setEmail,
      performAction: _performAction,
      ...passwordStepProps
    } = props
    return <EnterPasswordStep {...passwordStepProps} />
  },
  'enter-verification-code': (props: LoginStepProps) => {
    const {
      goToPreviousStep: _goToPreviousStep,
      setEmail: _setEmail,
      performAction: _performAction,
      ...enterVerificationCodeProps
    } = props
    return <EnterVerificationCodeStep {...enterVerificationCodeProps} />
  },
  'enter-new-password': (props: LoginStepProps) => {
    const {
      goToPreviousStep: _goToPreviousStep,
      setEmail: _setEmail,
      performAction: _performAction,
      ...enterNewPasswordProps
    } = props
    return <EnterNewPasswordStep {...enterNewPasswordProps} />
  },
  'password-reset': (props: LoginStepProps) => {
    const {
      setEmail: _setEmail,
      goToPreviousStep: _goToPreviousStep,
      ...forgotPasswordProps
    } = props
    return <ForgotPasswordStep {...forgotPasswordProps} />
  },
}

export default function LoginForm() {
  const location = useLocation()
  const isForceLogout = !!location?.state?.isForceLogout
  const { step, errorMessage, updateStep, performAction, setErrorMessage } =
    useUpdateStep('enter-email')
  const LoginStep = loginSteps[step]
  const [email, setEmail] = useState<string | undefined>()

  useEffect(() => {
    if (isForceLogout) setErrorMessage(isForceLogoutErrorMessage)
  }, [])

  return (
    <div className="p-4 lg:p-0">
      <LoginStep
        goToPreviousStep={() => performAction({ action: 'go-back', step })}
        errorMessage={errorMessage}
        updateStep={updateStep}
        email={email}
        setEmail={setEmail}
        performAction={performAction}
        logo={() => <Logo />}
      />
    </div>
  )
}
