import { useEffect } from 'react'
import { Form, useActionData, useNavigation } from '@remix-run/react'

import { Button, TextInput, Loader } from '~/design-system'
import { action } from '~/routes/login/route'

import { LoginFormData } from '../../hooks/useUpdateStep'

interface EnterPasswordProps {
  goToPreviousStep: () => void
  errorMessage: string
  updateStep: (stepData: LoginFormData) => void
  email: string | undefined
  logo: React.ComponentType
}

export default function EnterPassword(props: EnterPasswordProps) {
  const {
    errorMessage,
    updateStep,
    goToPreviousStep,
    email,
    logo: Logo,
  } = props
  const navigation = useNavigation()
  const isSubmitting = navigation.state !== 'idle'
  const data = useActionData<typeof action>()

  function resetErrorMessage() {
    updateStep({ errorMessage: '' })
  }

  useEffect(() => {
    updateStep(data)
  }, [data])

  useEffect(() => {
    resetErrorMessage()
  }, [])

  return (
    <Form method="post">
      <div className="flex flex-col items-center mt-6 relative">
        {isSubmitting && <Loader />}
        <div className="w-full max-w-sm flex flex-col items-center">
          <Logo />
          <h1 className="text-xl mt-4">Welcome back</h1>
          <p className="text-neutral-50-secondary-text text-sm mt-2">
            Enter your password to access your appointments
          </p>
          <div className="w-full mt-8">
            <TextInput
              label="Password"
              size="small"
              required
              name="password"
              type="password"
              autoFocus={!isSubmitting}
            />
          </div>
          <a
            role="button"
            className="text-xs text-neutral-50-secondary-text underline self-end mt-4"
            onClick={() => updateStep({ status: 'forgot-password' })}
          >
            Forgot your password?
          </a>
          {errorMessage && (
            <p className="text-danger-30-primary text-sm mt-4">
              {errorMessage}
            </p>
          )}
          <div className="w-full mt-4">
            <Button
              fullWidth
              name="step"
              value={'enter-password'}
              submit
              disabled={isSubmitting}
            >
              Sign In
            </Button>
          </div>
          <div className="w-full mt-4">
            <Button fullWidth onClick={goToPreviousStep} secondary>
              Back
            </Button>
          </div>
          <input type="hidden" hidden name="email" defaultValue={email} />
        </div>
      </div>
    </Form>
  )
}
