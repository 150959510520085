import { useFetcher } from '@remix-run/react'

import { Button, TextInput, Loader } from '~/design-system'
import { useOnFetcherLoaded } from '~/lib/fetcherStatus'

import Logo from './Logo'
import { LoginAction, LoginStep } from '../../business/LoginForm/loginSteps'
import { LoginFormData } from '../../hooks/useUpdateStep'

interface ForgotPasswordProps {
  errorMessage: string
  email?: string
  performAction: ({
    action,
    step,
  }: {
    action: LoginAction
    step: LoginStep
  }) => void
  updateStep: (stepData: LoginFormData) => void
}

export default function ForgotPassword(props: ForgotPasswordProps) {
  const { errorMessage, email, performAction, updateStep } = props
  const fetcher = useFetcher<{ ok: boolean; errorMessage: string }>()
  const isSubmitting = fetcher.state === 'submitting'
  const resetSent = !!fetcher.data?.ok

  useOnFetcherLoaded(() => {
    updateStep(fetcher.data)
  }, fetcher)

  function ResetSent() {
    return (
      <>
        <h1 className="text-xl mt-4 text-center">Password reset email sent!</h1>
        <p className="text-neutral-50-secondary-text text-sm mt-3 text-center">
          An email with a password reset link has been sent to {email}. Please
          check your inbox and follow the instructions in the email to reset
          your password. If you don't see the email in your inbox, kindly check
          your spam or junk folder.
        </p>
        {errorMessage && (
          <p className="text-danger-30-primary text-sm mt-4">{errorMessage}</p>
        )}
        <div className="w-full mt-4">
          <Button
            fullWidth
            disabled={isSubmitting}
            onClick={() =>
              performAction({ action: 'go-to-step', step: 'enter-email' })
            }
          >
            <p className="title-sm">Return to Login</p>
          </Button>
        </div>
      </>
    )
  }

  function ResetPending() {
    return (
      <>
        <h1 className="text-xl mt-4 text-center">Password reset</h1>
        <p className="text-neutral-50-secondary-text text-sm mt-3">
          No worries, we'll send you reset instructions.
        </p>
        <div className="w-full mt-8">
          <TextInput
            label="Email address"
            size="small"
            required
            name="email"
            autoComplete="email"
            type="email"
            value={email}
            autoFocus={!isSubmitting}
          />
        </div>
        {errorMessage && (
          <p className="text-danger-30-primary text-sm mt-4">{errorMessage}</p>
        )}
        <p className="text-xs text-neutral-50-secondary-text self-end mt-4">
          <span>Or return to </span>
          <a
            role="button"
            className="underline"
            onClick={() => {
              performAction({ action: 'go-to-step', step: 'enter-email' })
            }}
          >
            Sign in
          </a>
        </p>
        <div className="w-full mt-4">
          <Button
            fullWidth
            name="step"
            submit
            value={'password-reset'}
            disabled={isSubmitting}
          >
            <p className="title-sm">Reset Password</p>
          </Button>
        </div>
      </>
    )
  }

  return (
    <fetcher.Form method="post">
      <div className="flex flex-col items-center mt-6">
        <div className="w-full max-w-login flex flex-col items-center relative">
          {isSubmitting && <Loader />}
          <Logo />
          {resetSent ? <ResetSent /> : <ResetPending />}
        </div>
      </div>
    </fetcher.Form>
  )
}
