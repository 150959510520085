import { useFetcher, useSearchParams } from '@remix-run/react'

import { Button, TextInput, Loader } from '~/design-system'
import { useOnFetcherLoaded } from '~/lib/fetcherStatus'

import { LoginFormData } from '../../hooks/useUpdateStep'
import { getAppGlobals } from '~/global-data'

interface EnterEmailStepProps {
  errorMessage: string
  updateStep: (stepData: LoginFormData) => void
  email?: string
  setEmail: (email?: string) => void
  logo: React.ComponentType
}

export default function EnterEmail(props: EnterEmailStepProps) {
  const { errorMessage, updateStep, email, setEmail, logo: Logo } = props
  const fetcher = useFetcher<LoginFormData>({ key: 'enter-email' })
  const isSubmitting = fetcher.state === 'submitting'
  const [searchParams] = useSearchParams()
  const originIsZocdoc = searchParams.get('zocdoc') === 'true'
  const title = originIsZocdoc
    ? 'Thank you for booking with Circle Medical on Zocdoc!'
    : 'Sign in to view appointments'

  useOnFetcherLoaded(() => {
    updateStep(fetcher.data)
    setEmail(fetcher.data?.email)
  }, fetcher)

  const { BOOKING_URL } = getAppGlobals()

  return (
    <fetcher.Form method="post">
      <div className="flex flex-col items-center mt-6">
        <div className="w-full max-w-login flex flex-col items-center relative">
          {isSubmitting && <Loader />}
          <Logo />
          <h1 className="text-xl mt-4 text-center">{title}</h1>
          <p className="text-neutral-50-secondary-text text-sm mt-3">
            Enter your email to continue
          </p>
          <div className="w-full mt-8">
            <TextInput
              label="Email address"
              size="small"
              required
              name="email"
              type="email"
              value={email}
              autoFocus={!isSubmitting}
              data-testid="login-email-input"
            />
          </div>
          {errorMessage && (
            <p className="text-danger-30-primary text-sm mt-4">
              {errorMessage}
            </p>
          )}
          <div className="w-full mt-4">
            <Button
              fullWidth
              name="step"
              submit
              value={'enter-email'}
              disabled={isSubmitting}
            >
              <p className="title-sm">Continue</p>
            </Button>
          </div>
          {!originIsZocdoc && (
            <>
              <p className="text-neutral-50-secondary-text text-sm mt-4">
                Don't have an account yet?
              </p>
              <div className="w-full mt-4">
                <Button fullWidth secondary href={BOOKING_URL} target="_blank">
                  <p className="title-sm">Book an Appointment</p>
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </fetcher.Form>
  )
}
